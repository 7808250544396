var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { xs: "12", lg: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Tabs "),
                    _c("div", { staticClass: "card-header-actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "card-header-action",
                          attrs: {
                            href: "https://coreui.io/vue/docs/components/tabs",
                            rel: "noreferrer noopener",
                            target: "_blank"
                          }
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("docs")
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CTabs",
                        [
                          _c("CTab", { attrs: { title: "Home", active: "" } }, [
                            _vm._v(
                              " 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                            )
                          ]),
                          _c("CTab", { attrs: { title: "Profile" } }, [
                            _vm._v(
                              " 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                            )
                          ]),
                          _c(
                            "CTab",
                            { attrs: { title: "Disabled", disabled: "" } },
                            [
                              _vm._v(
                                " 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", lg: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Tabs ")]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CTabs",
                        { attrs: { variant: "pills" } },
                        [
                          _c("CTab", { attrs: { title: "Home", active: "" } }, [
                            _vm._v(
                              " 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                            )
                          ]),
                          _c("CTab", { attrs: { title: "Profile" } }, [
                            _vm._v(
                              " 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                            )
                          ]),
                          _c(
                            "CTab",
                            { attrs: { title: "Disabled", disabled: "" } },
                            [
                              _vm._v(
                                " 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", lg: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Tabs with icons ")]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CTabs",
                        [
                          _c(
                            "CTab",
                            { attrs: { active: "" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-calculator" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(
                                " 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", { attrs: { name: "cil-basket" } })
                                ],
                                1
                              ),
                              _vm._v(
                                " 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-chart-pie" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(
                                " 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", lg: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Tabs with icons ")]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CTabs",
                        { attrs: { "add-tab-classes": "mt-1" } },
                        [
                          _c(
                            "CTab",
                            { attrs: { active: "" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-calculator" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[0]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-basket" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[1]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-chart-pie" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[2]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { xs: "12", lg: "6" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [_vm._v(" Tabs vertical ")]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CTabs",
                        { attrs: { variant: "pills", vertical: "" } },
                        [
                          _c(
                            "CTab",
                            { attrs: { active: "" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-calculator" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[0]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-basket" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[1]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          ),
                          _c(
                            "CTab",
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-chart-pie" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.tabs[2]) + " ")
                                ],
                                1
                              ),
                              _vm._v(
                                " 3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }